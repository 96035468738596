import React, {useEffect} from 'react';
import {DevTool} from '@hookform/devtools';
import {useParams, useNavigate} from 'react-router-dom';
// Chakra
import {Alert, AlertDescription, AlertIcon, Box, Flex, FormLabel, Icon, Switch, Text, Tooltip, useDisclosure, useToast} from '@chakra-ui/react';
// React-hook-form
import {useForm} from 'react-hook-form';
import {LiaInfoCircleSolid} from 'react-icons/lia';
import moment from 'moment';
import 'moment-timezone';
import {useFlags} from 'launchdarkly-react-client-sdk';
// Components
import Footer from '../CreateShift/CreateShiftForm/Footer/Footer';
import UnitSelector from '../../../shared/components/UnitSelector/UnitSelector';
import PositionSelector from '../../../shared/components/PositionSelector/PositionSelector';
import DateSelector from './EditDateSelector/EditDateSelector';
import BonusSelector from '../CreateShift/CreateShiftForm/ShiftBonusSelector/ShiftBonusSelector';
import BreakSelector from '../../../shared/components/BreakSelector/BreakSelector';
import TimeSelector from '../../../shared/components/TimeSelector/TimeSelector';
import QualificationsSelector from '../../../shared/components/QualificationsSelector/QualificationsSelector';
import DescriptionInput from '../../../shared/components/DescriptionInput/DescriptionInput';
// Helper functions
import {useGetFacilityUnitPositionRate} from '../../../shared/gql/shiftTemplates/queries';
import {generateUnitOptions, handleDaySelection} from '../CreateShift/CreateShiftForm/helperFunctions';
import {
  getSelectedUnitPositions,
  getCurrentQualifications,
  getSelectedPositionQualifications,
  getGroupedQualificationOptions,
  getFirstPositionSelectedDescriptionAndInstructions,
  getCurrentBreakValues,
  handleBreakSelection,
  handleHasBonus,
  handleBonusChange,
} from '../../ShiftTemplates/helperFunctions';
import {useUpdateShiftOpening} from '../../../shared/gql/shifts/mutations';
import useAppSelector from '../../../shared/hooks/useAppSelector';
import useAppDispatch from '../../../shared/hooks/useAppDispatch';
import {useGetShift} from '../../../shared/gql/shifts/queries';
import {setEditedShift} from '../../../shared/slices/calShiftSlice';
import {useTimeZone, useFacilityId} from '../CreateShift/appSelectors';
// Types
import {Position, PositionV2} from '../../../shared/types/position';
import {ReduxState, SingleValue} from '../CreateShift/CreateShiftForm/CreateShiftForm.types';
import {FormValues, FacilityUnitModel} from './EditShift.types';
import {formatToDollarAmount} from '../../../shared/utils/helperFunctions';
import SubmitModal from './Overlay/SubmitModal';
import {trackShiftPageEdit} from '../../../shared/mixpanel/page-views';

const EditShift = () => {
  const currentEnv = process.env.REACT_APP_ENVIRONMENT;
  const [loading, setLoading] = React.useState(true);
  const dispatch = useAppDispatch();
  const {allowMultiPositions} = useFlags();
  const toast = useToast();
  const navigate = useNavigate();
  const [renderToGetValues, setRenderToGetValues] = React.useState(false);
  const [updateShiftOpening] = useUpdateShiftOpening();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {shiftId, openingId: paramsOpeningsId} = useParams<{shiftId: string; openingId: string}>();
  const [hasBonus, setHasBonus] = React.useState(false);
  const {data: detailsFromUseGetShift} = useGetShift({variables: {shiftId}});
  const [selectedTemplateDaysAsDates, setSelectedTemplateDaysAsDates] = React.useState<string[]>([]);
  const [isMulti, setIsMulti] = React.useState(false);
  const facilityId = useFacilityId();
  const timeZone = useTimeZone();
  useGetFacilityUnitPositionRate({variables: {facilityId}});
  const facilityUnits = useAppSelector(
    (state: ReduxState) => state.shiftTemplates.facilityUnits?.getFacilityUnitPositionRate.units
  );
  const specialQualifications = useAppSelector(
    (state: ReduxState) => state.shiftTemplates.facilityUnits?.getFacilityUnitPositionRate.qualifications
  );
  const shiftDetails = useAppSelector((state: ReduxState) => state.shifts?.shiftDetails?.getShiftDetail);
  const openingId = shiftDetails?.openingDetails?.find(
    (opening: {refShiftId: string | undefined}) => opening?.refShiftId?.toString() === paramsOpeningsId?.toString()
  )?.id;
  const defaultValues = shiftDetails
    ? {
        description: shiftDetails?.description || '',
        clockInInstruction: shiftDetails?.clockInInstruction || '',
        clockOutInstruction: shiftDetails?.clockOutInstruction || '',
      }
    : {unit: {value: '', label: ''}, description: '', clockInInstruction: '', clockOutInstruction: ''};
  const positions: Position[] = shiftDetails?.rate?.map((position: PositionV2) => ({
    name: position.name,
    rate: position.baseRate,
    adjustmentRate: position.adjustmentRate,
    id: position.id,
    weekendDiff: position.weekendDiff,
  }));
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    setFocus,
    setError,
    formState: {errors, dirtyFields, isSubmitting},
  } = useForm<FormValues>({
    defaultValues,
    mode: 'onTouched',
  });
  const {showRates} = useFlags();
  const selectedPositon = getValues('position');
  const selectedUnit = getValues('unit');
  const unitName = selectedUnit?.label;
  // Position functions
  const selectedUnitPositions = getSelectedUnitPositions(facilityUnits, unitName);
  const selectedPositionQualifications = getSelectedPositionQualifications({
    selectedUnitPositions,
    getValues,
  });
  // Unit functions
  const handleUnitSelection = (unit: SingleValue) => {
    setValue('unit', unit, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    setValue('position', []);
    setValue('qualifications', [], {shouldValidate: true, shouldDirty: true, shouldTouch: true});
  };
  const unitOptions = generateUnitOptions(facilityUnits);
  // Break functions
  const breakDefaultValues =
    (shiftDetails?.break && {
      value: shiftDetails.break,
      label: shiftDetails.break,
    }) ||
    undefined;
  const currentBreakValues = getCurrentBreakValues(watch, breakDefaultValues);
  // Qualifications functions
  const groupedQualificationOptions =
    selectedPositionQualifications &&
    getGroupedQualificationOptions(selectedPositionQualifications, specialQualifications);
  const qualificationsDefaultValues = shiftDetails?.qualifications.map((qualification: {id: string; name: string}) => ({
    value: qualification.id,
    label: qualification.name,
  }));
  const currentQualifications = getCurrentQualifications(watch, qualificationsDefaultValues);
  // Description functions
  const firstPositionSelectedDescriptionAndInstructions =
    getFirstPositionSelectedDescriptionAndInstructions(selectedPositionQualifications);
  const isConfirmed =
    shiftDetails?.openingDetails?.find(
      (opening: {refShiftId: string | undefined}) => opening?.refShiftId?.toString() === paramsOpeningsId?.toString()
    )?.status === 'Confirmed';
  const initialUtcStartTime = shiftDetails?.startTime;
  const initialUtcEndTime = shiftDetails?.endTime;
  const startTimeConvertedToLocalMoment = timeZone ? moment.utc(initialUtcStartTime).tz(timeZone) : null;
  const selectedUnitId = selectedUnit?.value;
  const facilityUnitDetails: FacilityUnitModel = facilityUnits?.find(unit => unit.id === selectedUnitId);
  const foundPositionDetails = facilityUnitDetails?.positions?.find(position => position.id === selectedPositon[0]?.id);
  const foundPositionRate = foundPositionDetails?.rate;
  const positionId = selectedPositon?.[0]?.id;
  const positionName = selectedPositon?.[0]?.name;
  const positionAdjustmentRate = selectedPositon?.[0]?.adjustmentRate;
  // ToDo: fix this type
  const weekendDiff = (selectedPositon as any)?.[0]?.weekendDiff;
  const positionRate = [
    {
      id: positionId,
      name: positionName,
      baseRate: foundPositionRate?.baseRate,
      subsidy: foundPositionRate?.subsidy,
      margin: foundPositionRate?.margin,
      adjustmentRate: positionAdjustmentRate,
      weekendDiff,
    },
  ];
  const qualificationIds = getValues('qualifications')?.map((qualification: any) => qualification.id) as string[];
  const convertDateTime = () => {
    if (!timeZone) return {startMomentConvertedToUtc: '', endMomentConvertedToUtc: ''};
    const localDate = getValues('date');
    const localMilitaryStartTime = getValues('startTime');
    const localMilitaryEndTime = getValues('endTime');
    const combinedStartTimeAndDateUtc = `${localDate}T${localMilitaryStartTime}:00Z`;
    const combinedEndTimeAndDateUtc = `${localDate}T${localMilitaryEndTime}:00Z`;
    const startTimeAndDateConvertedToMoment = moment.tz(combinedStartTimeAndDateUtc, 'YYYY-MM-DDTHH:mm', timeZone);
    const endTimeAndDateConvertedToMoment = moment.tz(combinedEndTimeAndDateUtc, 'YYYY-MM-DDTHH:mm', timeZone);
    // Check if end time is before start time, which would mean it's on the next day
    if (endTimeAndDateConvertedToMoment.isBefore(startTimeAndDateConvertedToMoment)) {
      endTimeAndDateConvertedToMoment.add(1, 'days');
    }
    // Convert to UTC
    const startMomentConvertedToUtc = startTimeAndDateConvertedToMoment.clone().utc().format('YYYY-MM-DDTHH:mm[Z]');
    const endMomentConvertedToUtc = endTimeAndDateConvertedToMoment.clone().utc().format('YYYY-MM-DDTHH:mm[Z]');
    return {startMomentConvertedToUtc, endMomentConvertedToUtc};
  };
  const editShiftPayload = {
    shiftId,
    openingId,
    facilityId,
    unitId: selectedUnit?.value,
    startTime: convertDateTime().startMomentConvertedToUtc,
    endTime: convertDateTime().endMomentConvertedToUtc,
    break: getValues('break') || '0mins',
    positionRate,
    bonus: !dirtyFields.bonus ? Number(getValues('bonus')) : Math.round(Number(getValues('bonus')) * 100),
    description:
      !getValues('description')?.length && firstPositionSelectedDescriptionAndInstructions?.description
        ? firstPositionSelectedDescriptionAndInstructions.description
        : `${watch('description')}` || '',
    clockInInstruction:
      !getValues('clockInInstruction')?.length && firstPositionSelectedDescriptionAndInstructions?.clockInInstruction
        ? firstPositionSelectedDescriptionAndInstructions.clockInInstruction
        : `${watch('clockInInstruction')}` || '',
    clockOutInstruction:
      !getValues('clockOutInstruction')?.length && firstPositionSelectedDescriptionAndInstructions?.clockOutInstruction
        ? firstPositionSelectedDescriptionAndInstructions.clockOutInstruction
        : `${watch('clockOutInstruction')}` || '',
    qualificationIds,
  };

  const formValidationCheck = () => {
    setRenderToGetValues(!renderToGetValues);
    const selectedDate = getValues('date');
    const selectedDateMoment = moment(selectedDate);
    const today = moment();
    const isPastDate = selectedDateMoment.isBefore(today, 'day');
    const isWithinTwoHoursFromOriginalStart = () => {
      const startTime = convertDateTime().startMomentConvertedToUtc;
      if (!startTimeConvertedToLocalMoment || !timeZone) return false;
      const selectedStartTime = moment.utc(startTime).tz(timeZone);
      const twoHoursAfterOriginalStart = startTimeConvertedToLocalMoment.clone().add(2, 'hours');
      const twoHoursBeforeOriginalStart = startTimeConvertedToLocalMoment.clone().subtract(2, 'hours');
      return selectedStartTime.isBetween(twoHoursBeforeOriginalStart, twoHoursAfterOriginalStart);
    };
    const isWithinTwoHours = isWithinTwoHoursFromOriginalStart();
    const invalidDueToShiftTime = isConfirmed && !isWithinTwoHours;
    const invalidDueToShiftDate = isPastDate;
    const invalidShiftEdit = invalidDueToShiftTime || invalidDueToShiftDate;
    if (!invalidShiftEdit) {
      onOpen();
    } else if (invalidDueToShiftTime) {
      setError('startTime', {
        type: 'manual',
        message: 'A confirmed shift can only be edited within 2 hours of the original start time.',
      });
    } else {
      setError('date', {
        type: 'manual',
        message: 'Date cannot be in the past',
      });
    }
  };

  const onSubmit = async (formData: FormValues) => {
    try {
      const response = await updateShiftOpening({
        variables: {updateShiftOpening: editShiftPayload},
      });
      if (response.data) {
        dispatch(setEditedShift(response.data));
        toast({
          title: 'Success',
          description: 'Shift edited successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        reset();

        const prevUnitName = localStorage.getItem('unitName');
        const prevStartDate = localStorage.getItem('startDate');
        const prevEndDate = localStorage.getItem('endDate');

        const fromPage = localStorage.getItem('fromPage') || '/calendar?view=weekly';

        navigate(fromPage, {
          state: {
            from: 'editShift',
            startDate: moment(formData.date).format('YYYY-MM-DD'),
            shiftId,
            prevUnitName,
            prevStartDate,
            prevEndDate,
          },
        });
      } else {
        toast({
          title: 'Error',
          description: 'Failed to edit shift.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };
  useEffect(() => {
    if (!shiftDetails || !facilityUnits || !timeZone) return;
    const facilityName = facilityUnits?.find(unit => unit?.id === shiftDetails.shiftUnitId)?.name;
    if (allowMultiPositions && selectedPositon?.length > 1) {
      setIsMulti(true);
    }
    setValue('unit', {label: facilityName as string, value: shiftDetails.shiftUnitId});
    setValue('position', positions);
    if (selectedPositon && selectedPositon.length > 1) {
      setIsMulti(true);
    }
    const localMilitaryStartTime = moment.utc(initialUtcStartTime).tz(timeZone).format('HH:mm');
    const localMilitaryEndTime = moment.utc(initialUtcEndTime).tz(timeZone).format('HH:mm');
    setValue('startTime', localMilitaryStartTime);
    setValue('endTime', localMilitaryEndTime);
    setValue('qualifications', shiftDetails?.qualifications);
    setValue('description', shiftDetails?.description);
    setValue('clockInInstruction', shiftDetails?.clockIn);
    setValue('clockOutInstruction', shiftDetails?.clockOut);
    const convertedStartTimeToDate = moment.tz(initialUtcStartTime, timeZone).format('YYYY-MM-DD');
    setValue('date', convertedStartTimeToDate);
    if (shiftDetails?.bonus) setHasBonus(true);
    setValue('bonus', shiftDetails?.bonus);
    setValue('break', shiftDetails?.break);
    const {
      shiftUnitId,
      startTime,
      endTime,
      break: breakTime,
      rate,
      bonus,
      description,
      clockIn,
      clockOut,
      qualifications,
    } = shiftDetails;
    dispatch(
      setEditedShift({
        updateShiftOpening: {
          shiftId,
          unitId: shiftUnitId,
          startDateTime: startTime,
          endDateTime: endTime,
          break: breakTime,
          positionRate: rate,
          bonus,
          description,
          clockInInstruction: clockIn,
          clockOutInstruction: clockOut,
          qualificationIds: qualifications.map((qualification: {id: string; name: string}) => qualification.id),
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftDetails, facilityUnits, timeZone]);
  const unitValues = getValues('unit')?.label;
  const positionValues = getValues('position')?.length > 0;
  useEffect(() => {
    const loadingCompleted = positionValues && unitValues;
    if (!loadingCompleted) return;
    setLoading(false);
  }, [unitValues, positionValues, shiftDetails, detailsFromUseGetShift]);
  const boxStyling = {
    marginBottom: '1rem',
    borderRadius: '0.5rem',
    padding: '1.5rem 3rem',
    backgroundColor: 'white',
  };

  useEffect(() => {
    if (shiftId && paramsOpeningsId) trackShiftPageEdit(shiftDetails, shiftId, paramsOpeningsId);
  }, [paramsOpeningsId, shiftDetails, shiftId]);

  const startShiftTime = moment(watch('startTime'), 'HH:mm A');
  const endShiftTime = moment(watch('endTime'), 'HH:mm A');
  type BreakMap = {
    [key: string]: string;
  };
  const breakMap: BreakMap = {
    undefined: '00:01',
    '0mins': '00:01',
    '15mins': '00:15',
    '30mins': '00:30',
    '45mins': '00:45',
  };

  const breakDuration = moment.duration(breakMap[watch('break') ?? 'undefined'] ?? '00:01');

  const duration = moment.duration(endShiftTime.diff(startShiftTime));

  const totalShiftTime = duration.subtract(breakDuration);

  if (totalShiftTime.asMilliseconds() < 0) {
    totalShiftTime.add(24, 'hours');
  }

  return (
    <Flex flexDirection="column" padding="1rem 2rem">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text fontWeight="600" fontSize="1.5rem" marginBottom="1.5rem">
          Edit opening
        </Text>
        <Box padding="1rem 1rem" w="50rem">
          <Box sx={boxStyling}>
            <Box marginBottom="1rem">
              <UnitSelector
                register={register}
                control={control}
                shiftTemplate={shiftDetails}
                isEditable={!isConfirmed}
                handleUnitSelection={handleUnitSelection}
                errors={errors}
                unitOptions={unitOptions}
                // getValues={getValues}
                loading={loading}
              />
            </Box>
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontWeight="600">Position</Text>
              {allowMultiPositions && (
                <Flex
                  justifyContent="space-around"
                  alignItems="center"
                  display={selectedUnitPositions ? 'inherit' : 'none'}
                >
                  <FormLabel fontWeight="400" htmlFor="multi-position" mb="0">
                    Allow multiple positions
                  </FormLabel>
                  <Flex alignItems="center" gap=".5rem">
                    <Switch
                      id="multi-position"
                      onChange={() => setIsMulti(!isMulti)}
                      disabled={selectedPositon?.length > 1}
                      isChecked={isMulti}
                    />
                    <Tooltip label="Multiple position types can apply for this shift" placement="top">
                      <Flex>
                        <Icon as={LiaInfoCircleSolid} boxSize="1.2rem" />
                      </Flex>
                    </Tooltip>
                  </Flex>
                </Flex>
              )}
            </Flex>
            <PositionSelector
              {...register('position')}
              selectedUnitPositions={selectedUnitPositions}
              getValues={getValues}
              errors={errors}
              watch={watch}
              setValue={setValue}
              isInCardData={undefined}
              isMulti={isMulti}
              isEditable={!isConfirmed}
              shiftTemplate={shiftDetails}
              loading={loading}
            />
            {errors.position && (
              <Text color="red.500" fontSize="sm">
                Please select a position
              </Text>
            )}
          </Box>
          <Box flexDirection="column" backgroundColor="white" borderRadius="0.5rem" sx={boxStyling}>
            <Box marginBottom="2rem">
              <DateSelector
                {...register('date')}
                setValue={setValue}
                getValues={getValues}
                handleDaySelection={handleDaySelection}
                errors={errors}
                watch={watch}
                selectedTemplateDaysAsDates={selectedTemplateDaysAsDates}
                setSelectedTemplateDaysAsDates={setSelectedTemplateDaysAsDates}
                setFocus={setFocus}
                shiftTemplate={shiftDetails}
                register={register}
                isEditable={!isConfirmed}
                loading={loading}
              />
              {errors.date && (
                <Text color="red.500" fontSize="sm">
                  {errors.date.message}
                </Text>
              )}
            </Box>
            <Flex justifyContent="space-between" flexDirection={['column', 'row']}>
              <TimeSelector 
                register={register} 
                watch={watch} 
                errors={errors}
                shiftTemplate={shiftDetails}
                setValue={setValue}
                isEditable
                loading={loading}
              />
              <BreakSelector
                breakDefaultValues={breakDefaultValues}
                shiftTemplate={shiftDetails}
                isEditable
                handleBreakSelection={handleBreakSelection}
                setValue={setValue}
                register={register}
                currentBreakValues={currentBreakValues}
                loading={loading}
              />
            </Flex>
            {totalShiftTime.asHours() > 16 && (
              <Box>
                <Alert
                  bg="#E8EAF1"
                  borderRadius="6px"
                  borderLeft="4px solid #38427E"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  marginBottom="1.5rem"
                >
                  <Flex>
                    <AlertIcon color="#38427E" marginRight="1rem" />
                    <Flex flexDirection="column">
                      <Flex flexDirection="column">
                        <AlertDescription>
                          Shift duration exceeds the maximum hours allowed for a shift. Please adjust the start or end
                          time for the shift or consider creating two shifts.
                        </AlertDescription>
                      </Flex>
                    </Flex>
                  </Flex>
                </Alert>
              </Box>
            )}
          </Box>
          {showRates && (
            <Box sx={boxStyling}>
              <BonusSelector
                shiftTemplate={null}
                control={control}
                hasBonus={hasBonus}
                setHasBonus={setHasBonus}
                handleHasBonus={handleHasBonus}
                register={register}
                formatToDollarAmount={formatToDollarAmount}
                watch={watch}
                handleBonusChange={handleBonusChange}
                setValue={setValue}
              />
            </Box>
          )}
          <Box sx={boxStyling}>
            <Box marginBottom="1rem">
              <QualificationsSelector
                qualificationsDefaultValues={qualificationsDefaultValues}
                groupedQualificationOptions={groupedQualificationOptions}
                shiftTemplate={shiftDetails}
                isEditable
                errors={errors}
                register={register}
                currentQualifications={currentQualifications}
                setValue={setValue}
                loading={loading}
              />
            </Box>
            <Box>
              <DescriptionInput
                shiftTemplate={shiftDetails}
                isEditable
                placeholder="You can enter shift descripton here"
                register={register}
                title="Shift Description"
                subText="Provide specialty requirements, desired expertise, or instructions if any."
                name="description"
                defaultValue={defaultValues?.description}
                loading={loading}
              />
            </Box>
          </Box>
          <Box sx={boxStyling}>
            <Box marginBottom="2rem">
              <DescriptionInput
                shiftTemplate={shiftDetails}
                isEditable
                placeholder="You can enter shift clock in instructions here"
                register={register}
                title="Clock In Instructions"
                subText="Provide clock in instructions if any."
                name="clockInInstruction"
                defaultValue={defaultValues?.clockInInstruction}
                loading={loading}
              />
            </Box>
            <Box>
              <DescriptionInput
                shiftTemplate={shiftDetails}
                isEditable
                placeholder="You can enter shift clock 0ut instructions here"
                register={register}
                title="Clock Out Instructions"
                subText="Provide clock out instructions if any."
                name="clockOutInstruction"
                defaultValue={defaultValues?.clockOutInstruction}
                loading={loading}
              />
            </Box>
          </Box>
          <Footer
            isEditShift
            startDate={initialUtcStartTime}
            shiftId={shiftId}
            resetForm={reset}
            formIsDirty={!!dirtyFields}
            isSubmitting={isSubmitting}
            primaryButtonText="Submit edit"
            formValidationCheck={formValidationCheck}
            totalShiftTime={totalShiftTime}
          />
          <SubmitModal isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} handleSubmit={handleSubmit} />
        </Box>
        {currentEnv === 'staging' || currentEnv === 'demo' ? <DevTool control={control} /> : null}
      </form>
    </Flex>
  );
};
export default EditShift;
